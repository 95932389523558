html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow-x: hidden;
}

html {
  overflow-y: scroll;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shake {
  animation: shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shakeAnimation {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url(./fonts/bahnschrift.ttf) format("truetype");
}

@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"),
    url(./fonts/arialroundbold.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu Medium";
  src: local("Ubuntu Medium"), url(./fonts/Ubuntu-M.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu Regular";
  src: local("Ubuntu Regular"), url(./fonts/Ubuntu-R.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"), url(./fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"), url(./fonts/Lato-Regular.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Bahnschrift";
  font-weight: lighter;
}

p {
  font-family: "Ubuntu Regular";
}

h4 {
  font-size: 17px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #184e77;
}
